import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import { tablet } from 'styles/breakpoints';

interface DisclaimerProps {
  bgColor?: string;
  text: string;
}
const Container = styled.div<{ bgColor?: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1.5rem 1rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 21.4375rem;
`;

const DisclaimerText = styled(Text)`
  color: var(--grey, #73899c);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const Disclaimer: FC<DisclaimerProps> = ({ bgColor, text }) => (
  <Container bgColor={bgColor}>
    <ContentContainer>
      <DisclaimerText>{text}</DisclaimerText>
    </ContentContainer>
  </Container>
);

export default Disclaimer;
